import React, { useEffect, useRef, useState, RefObject, Dispatch, SetStateAction } from "react";
import styles from './StepTwo.module.scss';
import dotsImg from '../../../assets/images/BigSteps/StepOne/dots.png';
import InputField from '../../../common/InputField/InputField';
import Button from '../../../common/Button/Button';
import mobileCat from '../../../assets/images/BigSteps/StepTwo/mobileCat.png'
import girl from '../../../assets/images/BigSteps/StepTwo/stepTwoGirl.png'
import step2 from '../../../assets/images/Landing/Steps/step2.svg';
import { IFinal } from "../../../App";
import firstMore from '../../../assets/images/BigSteps/StepOne/firstMore.png'
import secondMore from '../../../assets/images/BigSteps/StepOne/secondMore.png'
import thirdMore from '../../../assets/images/BigSteps/StepOne/thirdMore.png'
import fourthMore from '../../../assets/images/BigSteps/StepOne/fourthMore.png'
import ButtonSmall from "../../../common/ButtonSmall/ButtonSmall";

interface Props {
    final: IFinal,
    setFinal: Dispatch<SetStateAction<IFinal>>,
    nextPage: () => void
}

const StepTwo: React.FC<Props> = (props) => {
    const [valueStory, setValueStory] = useState("")

    // Validation

    const [emptyErrorStory, setEmptyErrorStory] = useState(false)
    const [lastAnswer, setLastAnswer] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const checkboxRef: RefObject<HTMLSpanElement> = useRef(null)


    const nextPageBtn = useRef<HTMLButtonElement>(null);
    const btnContainer = useRef<HTMLDivElement>(null);
    const img = useRef<HTMLImageElement>(null);
    const final = props.final;
    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {

        event.preventDefault();
        if (valueStory === "") {
            if (valueStory === "") setEmptyErrorStory(true)
        } else {
            final.step2.push(valueStory);
            setLastAnswer(valueStory)
            if (final.step2.length > 0 && nextPageBtn.current) {
                nextPageBtn.current.style.display = 'block';
                if(window.innerWidth <=450 && btnContainer.current && img.current){
                    nextPageBtn.current.style.width = "100%";
                    nextPageBtn.current.style.marginLeft = "0";
                    nextPageBtn.current.style.marginTop = "10px";
                    btnContainer.current.style.flexDirection ='column';
                    img.current.style.top = '29%';
                }
            }
            setValueStory("")
            props.setFinal(final)
            console.log(final)
            // props.nextPage()
        }
    }

    const nextPage = () => {
        props.nextPage();
    }


    return (
        <div className={styles.stepTwo}>
            <div className={styles.desc}>
                <div className={`${styles.desc_wrapper}  ${styles.wrapper}`}>
                    <div className={styles.desc_dotsImg}>
                        <img src={dotsImg} alt="human" />
                    </div>
                    <div className={styles.desc_img}>
                        {/*<span/>*/}
                        <img src={step2} alt="step1" />
                    </div>
                    <div className={styles.desc_text}>
                        <div className={styles.text_step}>
                            шаг 2
                        </div>
                        <div className={styles.text_title}>А что не принято<br />
                            в нашей семье?</div>
                        <div className={styles.text_desc}>
                            Есть вещи, которые не вписываются в нашу норму. Про что вы точно можете сказать: мы так не делаем?
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.form}>
                <div className={`${styles.form_wrapper} ${styles.wrapper}`}>
                    <div className={styles.form_catsTalk}>
                        <div className={styles.form_catsTalk_text}>У нас не принято <span>{lastAnswer || 'бояться спрашивать'}</span> </div>
                    </div>
                    <div className={styles.form_title}>
                        <div>Дополни высказывание<br /> «У нас <span>НЕ</span> принято…»</div>
                    </div>
                    <div className={styles.form_inputs}>
                        <InputField placeholder=""
                            placeholderBig=""
                            emptyError={emptyErrorStory}
                            setEmptyError={setEmptyErrorStory}
                            value={valueStory}
                            setValue={setValueStory}
                            textarea={true}
                            spanPlaceholder={true} />
                    </div>
                    <img className={styles.girl} src={girl} alt="girl" ref={img} />
                    <div className={styles.form_btn}>
                        <div className={styles.btn_container} ref={btnContainer}>
                            <Button  handleSubmit={handleSubmit} text={'ОТПРАВИТЬ'} style={{ width: '100%' }} />
                            <ButtonSmall text={"ДАЛЕЕ"} style={{ position: 'unset', margin: 'unset', marginBottom: 'auto', display: 'none', marginLeft: '10px' }} handleSubmit={nextPage} ref={nextPageBtn} />
                        </div>
                    </div>
                    <div className={styles.form_mobileImg}>
                        <div className={styles.mobileImg_text}> <div>У нас не принято </div><div className={styles.modile_long_text}>{lastAnswer || 'бояться спрашивать'}</div></div>
                    </div>
                    <div className={styles.morePresents}>
                        <div className={styles.morePresents__text}>Самые яркие варианты получат
                            <br />
                            дополнительные призы!</div>
                        <div className={styles.morePresents__blocks}>
                            <div className={styles.morePresents__block}>
                                <img src={firstMore} alt="image" />
                                <div className={styles.block__title}>
                                    Самый креативный
                                </div>
                                <div className={styles.block__desc}>
                                    Оцениваются находчивость и оригинальность
                                </div>
                            </div>
                            <div className={styles.morePresents__block}>
                                <img src={secondMore} alt="image" />
                                <div className={styles.block__title}>
                                    Самый мудрый
                                </div>
                                <div className={styles.block__desc}>
                                    Брось вызов древним философам!
                                </div>
                            </div>
                            <div className={styles.morePresents__block}>
                                <img src={thirdMore} alt="image" />
                                <div className={styles.block__title}>
                                    Самый ёмкий
                                </div>
                                <div className={styles.block__desc}>
                                    Как говорил классик, краткость — сестра таланта
                                </div>
                            </div>
                            <div className={styles.morePresents__block}>
                                <img src={fourthMore} alt="image" />
                                <div className={styles.block__title}>
                                    Самый смешной
                                </div>
                                <div className={styles.block__desc}>
                                    Тут всё итак понятно
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default StepTwo;