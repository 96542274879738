import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./StepThree.module.scss";
import Answer from "./Answer/Answer";
import Button from "../../../common/Button/Button";
import penguin from "../../../assets/images/BigSteps/StepThree/penguin.png";
import circle from "../../../assets/images/BigSteps/StepFour/yellow-circle.png";
import step3 from "../../../assets/images/Landing/Steps/step3.svg";
import api from "../../../api/api";
import { IFinal } from "../../../App";

interface Props {
  final: IFinal;
  setFinal: Dispatch<SetStateAction<IFinal>>;
  nextPage: () => void;
}

export interface IAnswer {
  _id: string;
  name: string;
  __v?: number;
}

const StepThree: React.FC<Props> = (props) => {
  const [answersText, setAnswersText] = useState<IAnswer[]>([]);
  const [finalAnswers, setFinalAnswers] = useState<IAnswer[]>([]);
  const [selectedAnswer, setSelectedAnswer] = useState<IAnswer | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    api.getAnswers(page).then((res) => {
      setAnswersText(res.data);
    });
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectAnswer = (answer: IAnswer): void => {
    setPage(page + 1);
    setFinalAnswers([...finalAnswers, answer]);
    setSelectedAnswer(null)
  };

  const answers = answersText.map((el) => {
    return (
      <Answer
        id={el._id}
        key={el._id}
        text={el.name}
        selectAnswer={setSelectedAnswer}
        isActive={el._id === selectedAnswer?._id}
      />
    );
  });

  const handleSubmit = (): void => {
    const final = props.final;
    final.step3 = finalAnswers;
    props.setFinal(final);

    props.nextPage();
  };

  let speech;

  switch (finalAnswers.length) {
    case 0:
      speech = "Выбери ответы коллег!";
      break;
    case 1:
      speech = "Так держать! Ещё парочку!";
      break;
    case 2:
      speech = "Так держать! Ещё парочку!";
      break;
    default:
      speech = "Ты можешь идти дальше!";
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.labelWrapper}>
        <img src={step3} alt="step 3" />
        <div>
          <h1>Выберите то, что вам близко</h1>
          <p>
            Отметьте варианты коллег, с которыми вы согласны или которые
            максимально отражают философию «Магнита»
          </p>
        </div>
      </div>
      <div className={styles.labelWrapperMob}>
        <img src={circle} alt="circle" />
        <div>
          <p className={styles.stepLabel}>ШАГ 3</p>
          <h1>Выберите то, что вам близко</h1>
          <p>
            Отметьте варианты коллег, с которыми вы согласны или которые
            максимально отражают философию «Магнита»
          </p>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.main}>
          <div className={styles.answers}>
            {answers}
            <div className={styles.btnsWrapper }>
              {selectedAnswer && (
                <div
                  className={`${styles.btnWrapper} ${
                    selectedAnswer ? styles._active : ""
                  }`}
                >
                  <Button
                    text="Отправить"
                    handleSubmit={() => selectAnswer(selectedAnswer)}
                  />
                </div>
              )}
              <div
                className={`${styles.btnWrapper} ${
                  finalAnswers.length >= 3 ? styles._active : ""
                }`}
                  style={{ marginLeft: "auto" }}
              >
                <Button
                  text="ВПЕРЕД!"
                  handleSubmit={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.cat}>
          <span className={styles.speech}>
            <p>{speech}</p>
          </span>
          <img src={penguin} alt="penguin" />
        </div>
      </div>
    </div>
  );
};

export default StepThree;
