import React, { useEffect, useRef, useState, Dispatch, SetStateAction } from "react";
import styles from './StepFive.module.scss';
import bgImg from '../../../assets/images/BigSteps/StepFive/bgText.png';
import InputField from './../../../common/InputField/InputField';
import Button from './../../../common/Button/Button';
import border from '../../../assets/images/BigSteps/StepFour/border-top.png';
import vk from '../../../assets/images/BigSteps/StepFive/VK.png';
import fb from '../../../assets/images/BigSteps/StepFive/FB.png';
import ok from '../../../assets/images/BigSteps/StepFive/OK.png';
import insta from '../../../assets/images/BigSteps/StepFive/INSTA.png';
import step5 from '../../../assets/images/Landing/Steps/step5.svg';
import OK from '../../../assets/images/BigSteps/StepFive/ok.png'
import FB from '../../../assets/images/BigSteps/StepFive/fb.png'
import INST from '../../../assets/images/BigSteps/StepFive/inst.png'
import VK from '../../../assets/images/BigSteps/StepFive/vk.png'
import { IFinal } from "../../../App";
import api from "../../../api/api";

interface Props {
    final: IFinal,
    setFinal: Dispatch<SetStateAction<IFinal>>,
}

const StepFive: React.FC<Props> = (props) => {
    const [valueStory, setValueStory] = useState("")
    const [isSubmited, setIsSubmited] = useState(false)
    // Validation

    const disabledBtn = useRef<HTMLButtonElement>(null);

    if (disabledBtn.current && isSubmited) {
        disabledBtn.current.classList.add(`${styles.disabled}`);
    }

    const [emptyErrorStory, setEmptyErrorStory] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (valueStory.length > 20) {

        }
    }, [valueStory])


    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        if (valueStory === "") {
            if (valueStory === "") setEmptyErrorStory(true)
        } else {
            const final = props.final
            final.step5 = valueStory
            props.setFinal(final)
            setIsSubmited(true)
            api.postFinal(final)
                .then(() => alert("Ваша заявка принята. Спасибо!"))

        }
        window.location.href = "#qr";
    }

    return (
        <div className={styles.stepFive}>
            <div className={styles.desc}>
                <div className={`${styles.desc_wrapper}  ${styles.wrapper}`}>
                    {/* <div className={styles.desc_bgImg}>
                        <img src={bgImg} alt="bgImg"/>
                    </div> */}
                    <div className={styles.desc_img}>
                        <img src={step5} alt="step5" />
                    </div>

                    <div className={styles.desc_text}>
                        <div className={styles.text_step}>
                            шаг 5
                        </div>
                        <div className={styles.text_title}>Поздравляем!</div>
                        <div className={styles.text_desc}>
                            <p>Это заключительный шаг, который даст вам шанс попасть
                                в число 10 лучших авторов компании!

                            </p>
                            <p>Придумайте креативную, короткую или смешную фразу
                                на футболку. Если ваша идея попадёт в ТОП-10 среди других идей коллег, мы вручим вам
                                комплект из 10 футболок
                                с вашей надписью!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.form}>
                <div className={`${styles.form_wrapper} ${styles.wrapper}`}>
                    <div className={styles.tShirtText}>{valueStory}</div>
                    <div className={styles.form_mobileBorder}>
                        <img src={border} alt="border-top" />
                    </div>
                    <div className={styles.form_title}>
                        <div>Какую фразу на майке вы хотите увидеть?</div>
                        <div className={styles.limit}>* максимально допустимое количество символов - 50</div>
                    </div>
                    <div className={styles.form_inputs}>
                        <InputField placeholder={valueStory ? '' : "Чтобы наша компания росла..."}
                            placeholderBig={valueStory ? '' : "Чтобы наша компания росла..."}
                            emptyError={emptyErrorStory}
                            setEmptyError={setEmptyErrorStory}
                            value={valueStory}
                            setValue={setValueStory}
                            textarea={true}
                            limit={50}
                            spanPlaceholder={true} />
                    </div>
                    <div className={styles.form_btn}>
                        <div className={styles.btn_container}>
                            <Button handleSubmit={handleSubmit} text={'ОТПРАВИТЬ'} ref={disabledBtn} />
                        </div>
                    </div>
                    {/* <div className={styles.form_mobileImg}>
                        <div className={styles.mobileImg_text}> <div>У нас принято </div><div>cмотреть глазами покупателя</div></div>
                        <img src={mobileCat} alt="cat" />
                    </div> */}
                </div>
            </div>
            <div className={styles.footer}>
                <div className={`${styles.footer_wrapper} ${styles.wrapper}`}>
                    <div className={styles.footer_text}>
                        <h1>Правила конкурса</h1>
                        <p>10 лучших надписей будут определены путём голосования в социальных сетях <a href='https://www.instagram.com/magnit.family/' target='_blank'>@magnit.family</a ></p>
                        <p>Победители выиграют по комплекту из 10 футболок для себя и своих команд</p>
                        <p> Ищи информацию по хештегу <span>#КодексМагнит</span></p>
                    </div>
                    <div className={styles.footer_img} id="qr">
                        <div className={styles.img_title}>Подписывайся на наши соцсети и следи за конкурсом!</div>
                        <div className={styles.desktopImg} >
                            <a href="https://vk.com/magnit.family" target='_blank'><img src={VK} alt="vk" /></a>
                            <a href="https://www.instagram.com/magnit.family/" target='_blank'><img src={INST} alt="insta" /></a>
                            <a href="https://www.facebook.com/magnit.family/" target='_blank'><img src={FB} alt="fb" /></a>
                            <a href="https://ok.ru/magnit.family" target='_blank'><img src={OK} alt="ok" /></a>
                        </div>
                        <div className={styles.img_socialmedia}>
                            <a href="https://vk.com/wall-180550223_32378"><img src={vk} alt="vk" /></a>
                            <a href="https://www.instagram.com/p/CRDri5EskG6/?utm_medium=copy_link"><img src={insta} alt="insta" /></a>
                            <a href="https://www.facebook.com/2626772970899958/posts/%D1%81%D0%B5%D0%BC%D1%8C%D1%8F-%D1%81%D0%B0%D0%BC%D0%BE%D0%B5-%D1%86%D0%B5%D0%BD%D0%BD%D0%BE%D0%B5-%D1%87%D1%82%D0%BE-%D0%B5%D1%81%D1%82%D1%8C-%D0%B2-%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8-%D1%87%D0%B5%D0%BB%D0%BE%D0%B2%D0%B5%D0%BA%D0%B0-%D0%B7%D0%B0-20-%D0%BB%D0%B5%D1%82-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D0%BC%D0%B0%D0%B3%D0%BD%D0%B8%D1%82-%D1%81%D0%BE%D0%B5%D0%B4%D0%B8%D0%BD%D0%B8%D0%BB-%D1%81%D0%BE/3379952395582008/"><img src={fb} alt="fb" /></a>
                            <a href="https://m.ok.ru/dk?st.cmd=altGroupMediaThemeComments"><img src={ok} alt="ok" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepFive;