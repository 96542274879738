import React from 'react';
import styles from "./ButtonSmall.module.scss"

interface Props {
    text: string,
    style?: React.CSSProperties,
    handleSubmit?: () => void
}

const ButtonSmall = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
    return (
        <button ref={ref} className={styles.btn} style={props.style} onClick={props.handleSubmit} >{props.text}</button>
    );
});

export default ButtonSmall;