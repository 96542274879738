import React, { useState, useEffect, useRef, RefObject, Dispatch, SetStateAction } from 'react';
import styles from './Register.module.scss'
import InputField from "../../common/InputField/InputField";
import Dropdown from "../../common/Dropdown/Dropdown";
import Button from "../../common/Button/Button";
import Checkbox from '@material-ui/core/Checkbox';
import bg from "../../assets/images/Register/registerBg.png"
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import api from "../../api/api";
import { useHistory } from "react-router";
import { IFinal } from "../../App";
import cat from '../../assets/images/Register/cat.svg';


const theme = createTheme({
    palette: {
        secondary: {
            main: "#FF0000",
        },
    },
});

interface Props {
    final: IFinal,
    setFinal: Dispatch<SetStateAction<IFinal>>
}

export interface IDistrict {
    _id: string,
    name: string
}

export interface IFormat {
    _id: string,
    formats: string
}

const Register: React.FC<Props> = (props) => {
    const history = useHistory()

    const [districts, setDistricts] = useState<IDistrict[]>([])
    const [departments, setDepartments] = useState<string[]>([])
    const [formats, setFormats] = useState<IFormat[]>([])
    const [shops, setShops] = useState<string[]>([])

    const [valueFirstName, setValueFirstName] = useState("")
    const [valueLastName, setValueLastName] = useState("")
    const [valueFathersName, setValueFathersName] = useState("")
    const [valueJob, setValueJob] = useState("")
    const [valueDistrict, setValueDistrict] = useState("")
    const [valueFormat, setValueFormat] = useState("")
    const [valueDepartment, setValueDepartment] = useState("")
    const [valueShop, setValueShop] = useState("")

    const [checked, setChecked] = useState(false)

    // Validation
    const [errorFormat, setErrorFormat] = useState("")
    const [errorDepartment, setErrorDepartment] = useState("")
    const [errorShop, setErrorShop] = useState("")

    const [emptyErrorFirstName, setEmptyErrorFirstName] = useState(false)
    const [emptyErrorLastName, setEmptyErrorLastName] = useState(false)
    const [emptyErrorFathersName, setEmptyErrorFathersName] = useState(false)
    const [emptyErrorJob, setEmptyErrorJob] = useState(false)
    const [emptyErrorDistrict, setEmptyErrorDistrict] = useState(false)
    const [emptyErrorFormat, setEmptyErrorFormat] = useState(false)
    const [emptyErrorDepartment, setEmptyErrorDepartment] = useState(false)
    const [emptyErrorShop, setEmptyErrorShop] = useState(false)


    const setEmptyValues = (type: string) => {

        shops.length > 0 && setShops([])
        valueShop !== "" && setValueShop("")
        errorShop !== "" && setErrorShop("")
              
        if (type === "department") {
            errorDepartment != "" && setErrorDepartment("")
            return;
        }

        if (type === "district") {
            errorFormat != "" && setErrorFormat("")
            formats.length > 0 && setFormats([])
            valueFormat !== "" && setValueFormat("")

        }

        departments.length > 0 && setDepartments([])
        valueDepartment !== "" && setValueDepartment("")
    }


    const handleDistrict = (e: string) => {
        setValueDistrict(e)
        setEmptyValues("district")
        api.getFormats()
            .then(res => setFormats(res.result))
    }

    const handleFormat = (e: string) => {
        setValueFormat(e)
        setEmptyValues("fommat")
        api.getDepartments(valueDistrict, e)
            .then(res => setDepartments(res.data))
    }

    const handleDepartments = (e: string) => {
        setValueDepartment(e);
        setEmptyValues("department")
        console.log()
        api.getShops(valueDistrict, e, valueFormat)
            .then(res => {
                if (res.data.length > 0){ 
                    setShops(res.data);
                    console.log(res.data);                    
                }
                else setErrorShop("Не найдено")

            })
    }


    useEffect(() => {
        api.getDistricts()
            .then(res => {
                setDistricts(res.data)
            })
    }, [])

    // useEffect(() => {
    //     if (valueDistrict === "") setErrorFormat("Сначала выберите округ")
    //     else {     

    //         api.getFormats()
    //             .then(res => setFormats(res.result))
    //     }
    // }, [valueDistrict])

    // useEffect(() => {
    //     if (valueFormat === "") setErrorDepartment("Сначала выберите формат")
    //     else {
    //         setErrorDepartment("")
    //         setDepartments([])
    //         setErrorFormat("")        
    //         setShops([])
    //         setValueShop("")
    //         setDepartments([])
    //         setValueDepartment("")

    //         api.getDepartments(valueDistrict,valueFormat)
    //             .then(res => setDepartments(res.data))
    //     }
    // }, [valueFormat, valueDistrict])

    // useEffect(() => {
    //     if (valueDepartment === "") setErrorShop("Сначала выберите подразделение")
    //     else {
    //         setErrorShop("")
    //         setShops([])

    //         api.getShops(valueDepartment, valueFormat)
    //             .then(res => {
    //                 if (res.data.length > 0) setShops(res.data)
    //                 else setErrorShop("Не найдено")

    //             })
    //     }
    // }, [valueDepartment, valueFormat])

    const checkboxRef: RefObject<HTMLSpanElement> = useRef(null)

    const disabledBtn = useRef<HTMLButtonElement>(null);

    if (disabledBtn.current) {
        disabledBtn.current.classList.add(`${styles.disabled}`);
    }

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        if (valueFirstName === "" || valueJob === "" || valueDistrict === "" ||
            valueFormat === "" ||        
            (departments.length > 0 && (valueDepartment === "")) ||
            (shops.length > 0 && (valueShop === "")) || !checked) {

            if (valueFirstName === "") setEmptyErrorFirstName(true)
            if (valueLastName === "") setEmptyErrorLastName(true)
            // if (valueFathersName === "") setEmptyErrorFathersName(true)
            if (valueJob === "") setEmptyErrorJob(true)
            if (valueDistrict === "") setEmptyErrorDistrict(true)
            if (valueFormat === "") setEmptyErrorFormat(true)
            if (departments.length > 0 && valueDepartment === "") setEmptyErrorDepartment(true)
            if (shops.length > 0 && valueShop === "") setEmptyErrorShop(true)
            if (!checked) {
                checkboxRef.current?.classList.add(styles.checkbox_error)
                setTimeout(() => {
                    checkboxRef.current?.classList.remove(styles.checkbox_error)
                }, 1200)
                // debugger
            }
        } else {
            const result = {
                firstName: valueFirstName,
                lastName: valueLastName,
                fathersName: valueFathersName,
                job: valueJob,
                district: valueDistrict,
                format: valueFormat,
                department: departments.length > 0 ?  valueDepartment : "",
                shop: shops.length > 0 ?  valueShop : ""
            }
            const final = props.final
            final.register = result
            props.setFinal(final)
            history.push("/step")
        }
    }

    if (disabledBtn.current && 
        checked &&
        valueFirstName.length > 1 && 
        valueJob.length > 1 &&
        valueDistrict.length > 1 &&
        valueFormat.length > 1 && 
        ((departments.length > 0 && (valueDepartment.length > 1)) || departments.length == 0) &&      
        ((shops.length > 0 && (valueShop.length > 1)) || shops.length == 0)        
        ) {
        disabledBtn.current.classList.remove(`${styles.disabled}`);
    }else{
        console.log("test");
    }
    

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }
    console.log(departments);
    console.log(shops);

    return (
        <div className={styles.wrapper}>

            <div className={styles.formWrapper}>
                <h1>Регистрация</h1>
                <form>
                    {/* <img src={bg} alt="background" className={styles.bg}/> */}
                    <img src={cat} alt="cat" className={styles.bgImg} />
                    <div className={styles.catsTalk}>
                        <div>Давай знакомиться</div>
                    </div>
                    <InputField placeholder="Фамилия"
                        placeholderBig="Выберите фамилию"
                        emptyError={emptyErrorLastName}
                        setEmptyError={setEmptyErrorLastName}
                        value={valueLastName}
                        setValue={setValueLastName} />
                    <InputField placeholder="Имя"
                        placeholderBig="Выберите имя"
                        emptyError={emptyErrorFirstName}
                        setEmptyError={setEmptyErrorFirstName}
                        value={valueFirstName}
                        setValue={setValueFirstName} />
                    <InputField placeholder="Отчество"
                        placeholderBig="Выберите отчество"
                        emptyError={emptyErrorFathersName}
                        setEmptyError={setEmptyErrorFathersName}
                        value={valueFathersName}
                        setValue={setValueFathersName} />
                    <InputField placeholder="Должность"
                        placeholderBig="Выберите должность"
                        emptyError={emptyErrorJob}
                        setEmptyError={setEmptyErrorJob} value={valueJob}
                        setValue={setValueJob} />

                    <Dropdown placeholder="Округ"
                        placeholderBig="Выберите округ"
                        value={valueDistrict}
                        options={districts.map(el => el.name)}
                        emptyError={emptyErrorDistrict}
                        setEmptyError={setEmptyErrorDistrict}
                        setValue={handleDistrict} />
                    <Dropdown placeholder="Формат"
                        placeholderBig="Выберите формат"
                        value={valueFormat}
                        options={formats.map(el => el.formats)}
                        emptyError={emptyErrorFormat}
                        setEmptyError={setEmptyErrorFormat}
                        setValue={handleFormat}
                        parentError={errorFormat} />

                    {departments.length > 0 && <Dropdown placeholder="Подразделение"
                        placeholderBig="Выберите бизнес-подразделение"
                        options={departments}
                        value={valueDepartment}
                        emptyError={emptyErrorDepartment}
                        setEmptyError={setEmptyErrorDepartment}
                        setValue={handleDepartments}
                        parentError={errorDepartment} />}


                    {shops.length > 0 && <Dropdown placeholder="Магазин"
                        value={valueShop}
                        placeholderBig="Выберите магазин"
                        options={shops}
                        emptyError={emptyErrorShop}
                        setEmptyError={setEmptyErrorShop}
                        setValue={setValueShop}
                        parentError={errorShop} />}



                    <div className={styles.agree}>
                        <span className={styles.checkbox} ref={checkboxRef}>
                            <ThemeProvider theme={theme}>
                                <Checkbox
                                    checked={checked}
                                    onChange={handleCheck}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </ThemeProvider>
                        </span>
                        <p>
                            Нажимая «Отправить» вы соглашаетесь с политкой конфиденциальности
                            и условиями обработки персональных данных
                        </p>
                    </div>
                    <div className="btn"> <Button handleSubmit={handleSubmit} text="ОТПРАВИТЬ" ref={disabledBtn} /></div>
                </form>
            </div>
        </div>
    );
};

export default Register;