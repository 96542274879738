import React, { useEffect, useState } from "react";
import "./App.scss";
import { Route, Switch, useHistory } from "react-router-dom";
import Register from "./components/Register/Register";
import logo from "./assets/images/Register/logo.png";
import user_icon from "./assets/images/Register/user_icon.png";
import Landing from "./components/Landing/Landing";
import BigSteps from "./components/BigSteps/BigSteps";
import { IAnswer } from "./components/BigSteps/StepThree/StepThree";
import { red } from "@material-ui/core/colors";

export interface IRegister {
  firstName: string;
  lastName: string;
  fathersName: string;
  job: string;
  district: string;
  format: string;
  department: string;
  shop: string;
}

export interface IEmployee {
  firstName: string;
  lastName: string;
  fathersName: string;
  job: string;
  district: string;
  format: string;
  department: string;
  shop: string;
  phone: string;
  mail: string;
  why: string;
}

export interface IFinal {
  register: IRegister;
  step1: string[];
  step2: string[];
  step3: IAnswer[];
  step4: IEmployee;
  step5: string;
}

function App() {
  const initialValue = {
    register: {
      firstName: "",
      lastName: "",
      fathersName: "",
      job: "",
      district: "",
      format: "",
      department: "",
      shop: "",
    },
    step1: [],
    step2: [],
    step3: [],
    step4: {
      firstName: "",
      lastName: "",
      fathersName: "",
      job: "",
      district: "",
      format: "",
      department: "",
      shop: "",
      phone: "",
      mail: "",
      why: "",
    },
    step5: "",
  };

  const [final, setFinal] = useState<IFinal>(initialValue);
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname !== "/") {
      history.push("/");
    }
  }, []);

  return (
    <div className="App">
      <header>
        <div>
          <img src={logo} alt="Magnit logo" />
          {/* <img src={user_icon} alt="user" /> */}
        </div>
      </header>
      <Switch>
        <Route path="/register">
          <Register final={final} setFinal={setFinal} />
        </Route>
        <Route path="/step">
          <BigSteps final={final} setFinal={setFinal} />
        </Route>
        <Route path="/" component={Landing} />
      </Switch>
      <footer >
        <div>
          сайт разработан в <a href="https://gusi-lebedi.ru">gusi-lebedi.ru</a>
        </div>
      </footer>
    </div>
  );
}

export default App;
