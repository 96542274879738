import React, { createRef, useLayoutEffect, useRef } from 'react';
import styles from "./Details.module.scss";
import poster from "../../../../assets/images/Landing/Details/welcome-poster.png";
import poster_mb from "../../../../assets/images/Landing/Details/welcome-mb-poster.png";
import { useHistory } from "react-router";


// const ParallaxCache = () => {
//     const { parallaxController } = useController();
//
//     useLayoutEffect(() => {
//         const handler = () => parallaxController.update();
//         window.addEventListener('load', handler);
//         return () => window.removeEventListener('load', handler);
//     }, [parallaxController]);
//
//     return null;
// };

// <ParallaxCache /> now can be used anywhere you have problems with cached attributes


interface Props {

}


const Details: React.FC<Props> = (props) => {
    const history = useHistory()

    const handleSubmit = () => {
        history.push("/register")
        window.scrollTo(0, 0)
    }

    const popupText = useRef<HTMLDivElement>(null);
    const openWindow = () => {
        if(window.innerWidth <= 414){
            document.body.style.position ='fixed';
        }
        if (popupText.current) {
            popupText.current.style.display = "block";
        }
    }

    const closeWindow = () => {
        if(window.innerWidth <= 414){
            document.body.style.position ='unset';
        }
        if (popupText.current) {
            popupText.current.style.display = "none";
        }
    }

    return (
        <div className={styles.wrapper}>
            <img src={poster} alt="poster" />
            <img src={poster_mb} alt="poster" />
            <div className={styles.popup} >
                <button onClick={openWindow}>подробнее о проекте</button>
                <div className={styles.popuptext_wrapper} ref={popupText}>
                    <div className={styles.popuptext} >
                        <p>В&nbsp;&laquo;Магните&raquo; трудится более 300&nbsp;000&nbsp;сотрудников. Мы&nbsp;все очень разные, но&nbsp;кое-что нас всех объединяет. И&nbsp;это не&nbsp;только логотип и&nbsp;род деятельности. У&nbsp;нас есть свои особенности, делающие компанию уникальной. Мы&nbsp;приглашаем тебя принять участие в&nbsp;формировании нашего собственного Кодекса &laquo;Магнит&raquo;: стань автором, впиши себя в&nbsp;историю компании!</p>
                        <p>Каждая организация&nbsp;&mdash; это свой особенный мир с&nbsp;неповторимой атмосферой. Мы&nbsp;легко чувствуем культурный код, но&nbsp;не&nbsp;всегда можем его облечь в&nbsp;слова. Или для тебя это не&nbsp;составит труда?</p>
                        <p>Итак, мы&nbsp;обращаемся к&nbsp;вам, коллеги! Напишите ёмко и&nbsp;точно, что у&nbsp;нас принято, а&nbsp;что&nbsp;&mdash; нет. Назовите ваших героев&nbsp;&mdash; кто эти люди, без кого &laquo;Магнит&raquo;&nbsp;&mdash; не&nbsp;&laquo;Магнит&raquo;. А&nbsp;в&nbsp;конце вас ждёт розыгрыш дизайнерских футболок с&nbsp;придуманной-вами-надписью для вашей команды. Итак, приступим!</p>
                        <button className={styles.buttonToRegister} onClick={closeWindow}>Закрыть</button>
                    </div>
                </div>
            </div>
            <button className={styles.buttonToRegister} onClick={handleSubmit}>Хочу выиграть!</button>
        </div>
    );
};

export default Details;