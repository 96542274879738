import React, {useRef} from 'react';
import styles from "./Steps.module.scss"

import step1 from "../../../../assets/images/BigSteps/StepOne/step1.svg"
import step2 from "../../../../assets/images/Landing/Steps/step2.svg"
import step3 from "../../../../assets/images/Landing/Steps/step3.svg"
import step4 from "../../../../assets/images/Landing/Steps/step4.svg"
import step5 from "../../../../assets/images/Landing/Steps/step5.svg"
import Step from "./Step";
import {useHistory} from "react-router";
import ButtonSmall from './../../../../common/ButtonSmall/ButtonSmall';

interface Props {

}

const Steps: React.FC<Props> = (props) => {
    const history = useHistory()
    const ref = useRef(null)
    const handleSubmit = () => {
        history.push("/register")
        window.scrollTo(0, 0)
    }
    return (
        <div className={styles.wrapper} ref={ref}>
            <div className={styles.content}>
                <h1>5 простых шагов </h1>
                <div className={styles.stairs}>
                        <Step img={step1}
                              bigText="Расскажите, что у нас принято"
                              smallText="Что, на ваш взгляд, характерно для «Магнита»"/>
                    <Step img={step2}
                          bigText="А что не принято в нашей семье?"
                          smallText="Что вообще не про нас?"/>
                    <Step img={step3}
                          bigText="Выберите то, что вам ближе"
                          smallText="Проголосуйте за ответы ваших коллег"/>
                    <Step img={step4}
                          bigText="Назовите Человка «Магнита»"
                          smallText="Кто из коллег – ваш герой и опора компании?"/>
                    <Step img={step5}
                          bigText="Придумайте надпись и выиграйте футболку"
                          smallText="Авторы самых классных вариантов получат целый комплект футболок"/>
                </div>
            </div>
            <div className={styles.line}></div>
            <button style={{bottom: "-86px", zIndex:1}} onClick={handleSubmit}>Стать автором</button>

            <div className={styles.polygon}>
            </div>
        </div>
    );
};

export default Steps;