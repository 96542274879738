import React from "react";
import styles from "./Answer.module.scss";
import { IAnswer } from "../StepThree";

interface Props {
  id: string;
  text: string;
  selectAnswer: (answer: IAnswer) => void;
  isActive?: boolean;
}

const Answer: React.FC<Props> = (props) => {
  return (
    <div
      className={`${styles.wrapper} ${props.isActive ? styles.active : ""}`}
      onClick={() => props.selectAnswer({ _id: props.id, name: props.text })}
    >
      {props.text}
    </div>
  );
};

export default Answer;
