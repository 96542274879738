import React, {Dispatch, SetStateAction, useState, useEffect, useRef} from 'react';
import styles from "./Dropdown.module.scss"
import arrow from "../../assets/images/Register/dropdownArrow.svg"

interface Props {
    placeholder: string,
    placeholderBig: string,
    
    
    // content: string[],    
    // setContent: Dispatch<SetStateAction<string>>,
    options: string[],    
    setValue: Dispatch<SetStateAction<string>> | ((value : string) => void),
    value: string,
    parentError?: string,
    emptyError: boolean,
    setEmptyError: Dispatch<SetStateAction<boolean>>,
}

const useFocus = (shouldFocus : boolean) => {
    const ref = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if(shouldFocus) {
            ref.current?.focus();
        } else {
            ref.current?.blur();
        }

    }, [shouldFocus])

    return ref;
}

const Dropdown: React.FC<Props> = (props) => {
    const [isDropdownActive, setIsDropDownActive] = useState(false)
    const [isError, setIsError] = useState(false)
    const [localValue, setLocalValue] = useState<string>("")

    const toggleDropdown = (): void => {
        if (props.parentError === "" || props.parentError === undefined) {
            setIsError(false)
            setIsDropDownActive(!isDropdownActive)
        } else setIsError(true)
    }

    const ref = useFocus(isDropdownActive)

    const onContentClick = (content: string): void => {          
          props.setValue(content)          
          setLocalValue(content)        
          props.setEmptyError(false)        
    }

    const onClearClick =(e : any)=>{
        onContentClick("")
        e.stopPropagation();
    }

    useEffect(() => {
        if (props.parentError === "") setIsError(false)
    }, [props.parentError])

    useEffect(()=>{   
            setLocalValue(props.value)
    },[props.value])

    const onInputChange=(e :any )=>{ 
        setLocalValue(e.target.value)        
    }

    
    const content = props.options.filter(f=>localValue !=='' ? f.toLowerCase().includes(localValue.toLowerCase()) : true)
        .map(el => <p className={styles.contentInner} key={el} onClick={() => onContentClick(el)}>{el}</p>)
    
    const content2 = props.options.filter(f=>localValue !=='' ? !f.toLowerCase().includes(localValue.toLowerCase()) : true)
        .map(el => <p className={styles.contentInner} key={el} onClick={() => onContentClick(el)}>{el}</p>)
    


    return (
        <div className={`${styles.wrapper} ${(isError || props.emptyError) ? styles.wrapper_error : ""}`} onClick={toggleDropdown}>
            <div className={`${styles.placeholderWrapper} ${styles.placeholderWrapper_mob}`}>
                {props.placeholder}
                <img src={arrow} className={`${styles.arrow} ${isDropdownActive && styles.arrow_active}`} alt=""/>
            </div>
            <div className={styles.placeholderWrapper}>
                {props.placeholderBig}
                <img src={arrow} className={`${styles.arrow} ${isDropdownActive && styles.arrow_active}`} alt=""/>
            </div>
            {/* <div className={styles.cross} onClick={onClearClick}>&#10060;</div> */}
            <input 
                type="text"
                placeholder={props.placeholderBig}
                onChange={onInputChange}
                value={localValue}
                className={`${styles.input} ${(isError || props.emptyError) && styles.input_error}`}
                ref={ref}       
            />
            <hr className={`${styles.hr} ${isDropdownActive && styles.hr_active}`}/>
            <div className={`${styles.dropdownContent} ${isDropdownActive && styles.dropdownActive}`}>
            {content}  
            {localValue !== "" && <p>-</p>}
            {localValue !== "" && content2}           
            </div>
            
        </div>
    );
};

export default Dropdown;