import React from 'react';
import styles from "./Item.module.scss";

interface Props {
    emoji: string,
    textBig: string,
    textSmall: string
}

const Item: React.FC<Props> = (props) => {
    return (
        <div className={styles.item}>
            <span>{props.emoji}</span>
            <h3>{props.textBig}</h3>
            <p>{props.textSmall}</p>
        </div>
    );
};

export default Item;