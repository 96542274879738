import React from 'react';
import styles from "./Step.module.scss";



interface Props {
    img: string,
    bigText: string,
    smallText: string
}

const Step: React.FC<Props> = (props) => {
    return (
            <div className={`${styles.step} _anim-items _anim-no-hide`}>
                    <img src={props.img} alt="step"/>
                    <h3>{props.bigText}</h3>
                    <p>{props.smallText}</p>
            </div>
    );
};

export default Step;