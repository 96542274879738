import axios from "axios";


const obj = axios.create({baseURL: process.env.REACT_APP_SERVER_URL})

const api = {
    getAnswers(page) {
        return obj.get(`answer?range=[${page * 5 - 5}, ${page * 5 - 1}]`)
            .then(res => res.data)
    },
    getDistricts() {
        return obj.get("districts")
            .then(res => res.data)
    },
    getFormats() {
        return obj.get("format")
            .then(res => res.data)
    },
    getDepartments(district, format) {
        return obj.get(`departments?district=${district}&departmentFormat=${format}`)
            .then(res => res.data)
    },
    getShops(district, department, format) {
        return obj.get(`shops?district=${district}&department=${department}&departmentFormat=${format}`)
            .then(res => res.data)
    },
    postFinal(final) {
        return obj.post("registration", final)
    }
}

export default api