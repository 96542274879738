import React from 'react';
import styles from "./Welcome.module.scss"
import cashier from "../../../../assets/images/Landing/Welcome/cashier.svg"
import welcome from "../../../../assets/images/Landing/Welcome/welcome_header.png"
import welcome_mobile from "../../../../assets/images/Landing/Welcome/bgMob.png"
import cat from "../../../../assets/images/Landing/Welcome/cat.svg"
import workers from "../../../../assets/images/Landing/Welcome/workers.svg"
import letters from "../../../../assets/images/Landing/Welcome/letters.svg"


interface Props {

}

const Welcome: React.FC<Props> = (props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.imagesWrapper}>
                <img src={welcome} alt="welcome" />
            </div>
            <div className={styles.polygon}></div>
        </div>
    );
};

export default Welcome;