import React from 'react';
import styles from "./Button.module.scss"

interface Props {
    handleSubmit?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    text: string,
    style?: React.CSSProperties, 
    isDisabled?: boolean,
}

const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
    return (
        <button className={styles.btn}  ref={ref} onClick={props.handleSubmit} style={props.style}><p>{props.text}</p></button>
    );
});

export default Button;