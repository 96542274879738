import React, {useEffect} from 'react';
import styles from "./Landing.module.scss"
import Welcome from "./blocks/Welcome/Welcome";
import Details from "./blocks/Details/Details";
import Steps from "./blocks/Steps/Steps";
import Prizes from "./blocks/Prizes/Prizes";
import animate from "../../assets/scripts/animation";
import stylesStep from "./blocks/Steps/Step.module.scss";
import stylesDetails from "./blocks/Details/Details.module.scss";

interface Props {

}

const Landing: React.FC<Props> = (props) => {
    useEffect(() => {
        // styles of blocks where animation occurs
        animate({
            "Step": {style: stylesStep, speed: 10},
            "Details": {style: stylesDetails, speed: 2}
        })
    }, [])

    return (
        <div>
            <Welcome/>
            <Details/>
            <Steps/>
            <Prizes/>
        </div>
    );
};

export default Landing;