import React, { useEffect, useRef, useState, RefObject, Dispatch, SetStateAction } from "react";
import styles from './StepOne.module.scss';
import dotsImg from '../../../assets/images/BigSteps/StepOne/dots.png';
import InputField from './../../../common/InputField/InputField';
import Button from './../../../common/Button/Button';
import firstMore from '../../../assets/images/BigSteps/StepOne/firstMore.png'
import secondMore from '../../../assets/images/BigSteps/StepOne/secondMore.png'
import thirdMore from '../../../assets/images/BigSteps/StepOne/thirdMore.png'
import fourthMore from '../../../assets/images/BigSteps/StepOne/fourthMore.png'
import cat from '../../../assets/images/BigSteps/StepOne/stepOneCat.png'
import { IFinal } from "../../../App";
import { useHistory } from "react-router";
import step1 from '../../../assets/images/BigSteps/StepOne/step1.svg';
import ButtonSmall from './../../../common/ButtonSmall/ButtonSmall';

interface Props {
    final: IFinal,
    setFinal: Dispatch<SetStateAction<IFinal>>,
    nextPage: () => void
}

const StepOne: React.FC<Props> = (props) => {
    const history = useHistory()

    const [valueStory, setValueStory] = useState("")
    // Validation
    const [emptyErrorStory, setEmptyErrorStory] = useState(false)
    const [lastAnswer, setLastAnswer] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    const nextPageBtn = useRef<HTMLButtonElement>(null);
    const btnContainer = useRef<HTMLDivElement>(null);
    const img = useRef<HTMLImageElement>(null);
    const final = props.final;
    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
         
        event.preventDefault();
        if (valueStory === "") {
            if (valueStory === "") setEmptyErrorStory(true)
        } else {
            final.step1.push(valueStory);
            setLastAnswer(valueStory)
            if(final.step1.length > 0 && nextPageBtn.current){
                nextPageBtn.current.style.display = 'block';
                if(window.innerWidth <=450 && btnContainer.current && img.current){
                    nextPageBtn.current.style.width = "100%";
                    nextPageBtn.current.style.marginLeft = "0";
                    nextPageBtn.current.style.marginTop = "10px";
                    btnContainer.current.style.flexDirection ='column';
                    img.current.style.top = '27%';
                }
            }
            setValueStory("")
            props.setFinal(final)
            console.log(final)
            // props.nextPage()
        }
    }
    
    const nextPage = () => {
            props.nextPage();
    }


    return (
        <div className={styles.stepOne}>
            <div className={styles.desc}>
                <div className={`${styles.desc_wrapper}  ${styles.wrapper}`}>
                    <div className={styles.desc_dotsImg}>
                        <img src={dotsImg} alt="human" />
                    </div>
                    <div className={styles.desc_img}>
                        {/*<span/>*/}
                        <img src={step1} alt="step1" />
                    </div>
                    <div className={styles.desc_text}>
                        <div className={styles.text_step}>
                            шаг 1
                        </div>
                        <div className={styles.text_title}>Расскажите,<br />
                            что принято<br />
                            в «Магните»?
                        </div>
                        <div className={styles.text_desc}>
                            {/* <p>В компании трудится более <span>300 000</span> сотрудников.</p>
                            <p>Мы все очень разные, но кое-что нас всех объединяет.</p>
                            <p>И это не только логотип 😊 и род деятельности.</p> */}
                            <p>Кодекс «Магнит» сам себя не напишет, это сделать можете только вы! Напишите максимально
                                точно, что, как вам кажется, является частью нашего культурного кода.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.form}>
                <div className={`${styles.form_wrapper} ${styles.wrapper}`}>
                    <div className={styles.form_catsTalk}>
                        <div>У нас принято <span>{lastAnswer || 'cмотреть глазами покупателя'}</span></div>
                    </div>
                    <div className={styles.form_title}>
                        <div>Дополни высказывание<br /> «У нас принято…»</div>
                    </div>
                    <div className={styles.form_inputs}>
                        <InputField placeholder="...улыбаться коллегам"
                            placeholderBig="...улыбаться коллегам"
                            emptyError={emptyErrorStory}
                            setEmptyError={setEmptyErrorStory}
                            value={valueStory}
                            setValue={setValueStory}
                            textarea={true}
                            spanPlaceholder={true} />
                    </div>
                    <img className={styles.cat} src={cat} alt="cat" ref={img}/>
                    <div className={`${styles.form_btn} `}>
                        <div className={styles.btn_container} ref={btnContainer}>
                            <Button  handleSubmit={handleSubmit} text={'ОТПРАВИТЬ'} style={{width:'100%'}} />
                            <ButtonSmall text={"ДАЛЕЕ"} style={{position: 'unset', margin: 'unset', marginBottom: 'auto', display: 'none', marginLeft: '10px'}} handleSubmit={nextPage} ref={nextPageBtn}/>
                        </div>
                    </div>
                    <div className={styles.form_mobileImg}>
                        <div className={styles.mobileImg_text}>
                            <div>У нас принято</div>
                            <div>{lastAnswer || 'cмотреть глазами покупателя'}</div>
                        </div>
                        {/* <img src={mobileCat} alt="cat"/> */}
                    </div>
                    <div className={styles.morePresents}>
                        <div className={styles.morePresents__text}>Самые яркие варианты получат
                         <br/>
                            дополнительные призы!</div>
                        <div className={styles.morePresents__blocks}>
                            <div className={styles.morePresents__block}>
                                <img src={firstMore} alt="image" />
                                <div className={styles.block__title}>
                                Самый креативный
                                </div>
                                <div className={styles.block__desc}>
                                Оцениваются находчивость и оригинальность
                                </div>
                            </div>
                            <div className={styles.morePresents__block}>
                                <img src={secondMore} alt="image" />
                                <div className={styles.block__title}>
                                Самый мудрый
                                </div>
                                <div className={styles.block__desc}>
                                Брось вызов древним философам!
                                </div>
                            </div>
                            <div className={styles.morePresents__block}>
                                <img src={thirdMore} alt="image" />
                                <div className={styles.block__title}>
                                Самый ёмкий
                                </div>
                                <div className={styles.block__desc}>
                                Как говорил классик, краткость — сестра таланта
                                </div>
                            </div>
                            <div className={styles.morePresents__block}>
                                <img src={fourthMore} alt="image" />
                                <div className={styles.block__title}>
                                Самый смешной
                                </div>
                                <div className={styles.block__desc}>
                                Тут всё итак понятно
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepOne;