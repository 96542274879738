import React, {Dispatch, SetStateAction, useState} from 'react';
import styles from "./BigSteps.module.scss"
import StepOne from './StepOne/StepOne';
import StepTwo from './StepTwo/StepTwo';
import StepThree from "./StepThree/StepThree";
import StepFour from "./StepFour/StepFour";
import StepFive from './StepFive/StepFive';
import {IFinal} from "../../App";

interface Props {
    final: IFinal,
    setFinal: Dispatch<SetStateAction<IFinal>>
}

const BigSteps: React.FC<Props> = (props) => {
    const [activeStep, setActiveStep] = useState(1)

    const handleSteps = (step: number): void => {
        setActiveStep(step)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.stepway}>
                <div className={`${styles.stepway_wrapper} ${styles.wrapper}`}>
                    <div className={`${styles.step} ${activeStep === 1 ? styles.active : ""}`}
                        //  onClick={() => handleSteps(1)}
                         >Шаг 1
                    </div>
                    <div className={`${styles.step} ${activeStep === 2 ? styles.active : ""}`}
                        //  onClick={() => handleSteps(2)}
                         >Шаг 2
                    </div>
                    <div className={`${styles.step} ${activeStep === 3 ? styles.active : ""}`}
                        //  onClick={() => handleSteps(3)}
                         >Шаг 3
                    </div>
                    <div className={`${styles.step} ${activeStep === 4 ? styles.active : ""}`}
                        //  onClick={() => handleSteps(4)}
                         >Шаг 4
                    </div>
                    <div className={`${styles.step} ${activeStep === 5 ? styles.active : ""}`}
                        //  onClick={() => handleSteps(5)}
                         >Шаг 5
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.progressWrapper}>
                    <div className={`${styles.progress} ${activeStep >= 3 ? styles.over50 : ""} ${styles[`step${activeStep}`]}`}>
                        <div className={styles.clipper}>
                            <div className={styles.firstHalf}/>
                            <div className={styles.value}/>
                        </div>
                    </div>
                </div>
                {activeStep === 1 && <StepOne final={props.final}
                                              setFinal={props.setFinal}
                                              nextPage={() => handleSteps(2)}/>}
                {activeStep === 2 && <StepTwo final={props.final}
                                              setFinal={props.setFinal}
                                              nextPage={() => handleSteps(3)}/>}
                {activeStep === 3 && <StepThree final={props.final}
                                                setFinal={props.setFinal}
                                                nextPage={() => handleSteps(4)}/>}
                {activeStep === 4 && <StepFour final={props.final}
                                               setFinal={props.setFinal}
                                               nextPage={() => handleSteps(5)}/>}
                {activeStep === 5 && <StepFive final={props.final}
                                               setFinal={props.setFinal}/>}
            </div>

        </div>
    );
};

export default BigSteps;