import React, {
  useState,
  RefObject,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import styles from "./StepFour.module.scss";
import InputField from "../../../common/InputField/InputField";
import Dropdown from "../../../common/Dropdown/Dropdown";
import Button from "../../../common/Button/Button";
import backgroundImg from "../../../assets/images/BigSteps/StepFour/man.png";
import circle from "../../../assets/images/BigSteps/StepFour/halfCircle.png";
import api from "../../../api/api";
import { IDistrict, IFormat } from "../../Register/Register";
import step4 from "../../../assets/images/Landing/Steps/step4.svg";
import { IFinal } from "../../../App";
import { isValidEmail } from "../../../utils/isValidEmail";

interface Props {
  final: IFinal;
  setFinal: Dispatch<SetStateAction<IFinal>>;
  nextPage: () => void;
}

const StepFour: React.FC<Props> = (props) => {
  const [districts, setDistricts] = useState<IDistrict[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);
  const [formats, setFormats] = useState<IFormat[]>([]);
  const [shops, setShops] = useState<string[]>([]);

  const [valueName, setValueName] = useState("");
  const [valuePhone, setValuePhone] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [valueStory, setValueStory] = useState("");
  const [valueSurname, setValueSurname] = useState("");
  const [valuePatronymic, setValuePatronymic] = useState("");
  const [valueJob, setValueJob] = useState("");
  const [valueDistrict, setValueDistrict] = useState("");
  const [valueFormat, setValueFormat] = useState("");
  const [valueDepartment, setValueDepartment] = useState("");
  const [valueShop, setValueShop] = useState("");

  const [checked, setChecked] = useState(false);

  // Validation

  const [errorFormat, setErrorFormat] = useState("");
  const [errorDepartment, setErrorDepartment] = useState("");
  const [errorShop, setErrorShop] = useState("");

  const [emptyErrorName, setEmptyErrorName] = useState(false);
  const [emptyErrorPhone, setEmptyErrorPhone] = useState(false);
  const [emptyErrorEmail, setEmptyErrorEmail] = useState(false);
  const [emptyErrorStory, setEmptyErrorStory] = useState(false);
  const [emptyErrorSurname, setEmptyErrorSurname] = useState(false);
  const [emptyErrorPatronymic, setEmptyErrorPatronymic] = useState(false);
  const [emptyErrorJob, setEmptyErrorJob] = useState(false);
  const [emptyErrorDistrict, setEmptyErrorDistrict] = useState(false);
  const [emptyErrorFormat, setEmptyErrorFormat] = useState(false);
  const [emptyErrorDepartment, setEmptyErrorDepartment] = useState(false);
  const [emptyErrorShop, setEmptyErrorShop] = useState(false);

  const [error, setError] = useState<null | string>(null);


  const setEmptyValues = (type: string) => {

    shops.length > 0 && setShops([])
    valueShop !== "" && setValueShop("")
    errorShop !== "" && setErrorShop("")
    //         setShops([])
    if (type === "department") {
      errorDepartment != "" && setErrorDepartment("")
      return;
    }

    if (type === "district") {
      errorFormat != "" && setErrorFormat("")
      formats.length > 0 && setFormats([])
      valueFormat !== "" && setValueFormat("")

    }

    departments.length > 0 && setDepartments([])
    valueDepartment !== "" && setValueDepartment("")
  }


  const handleDistrict = (e: string) => {
    setValueDistrict(e)
    setEmptyValues("district")
    api.getFormats()
      .then(res => setFormats(res.result))
  }

  const handleFormat = (e: string) => {
    setValueFormat(e)
    setEmptyValues("fommat")
    api.getDepartments(valueDistrict, e)
      .then(res => setDepartments(res.data))
  }

  const handleDepartments = (e: string) => {
    setValueDepartment(e);
    setEmptyValues("department")
    console.log()
    api.getShops(valueDistrict, e, valueFormat)
      .then(res => {
        if (res.data.length > 0) {
          setShops(res.data);
          console.log(res.data);
        }
        else setErrorShop("Не найдено")

      })
  }



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //
  useEffect(() => {
    api.getDistricts().then((res) => {
      setDistricts(res.data);
    });
  }, []);

  // useEffect(() => {
  //   if (valueDistrict === "") setErrorFormat("Сначала выберите округ");
  //   else {
  //     setErrorFormat("");
  //     setFormats([]);
  //     setValueFormat("");
  //     setShops([]);
  //     setValueShop("");
  //     setDepartments([]);
  //     setValueDepartment("");
  //     api.getFormats().then((res) => setFormats(res.result));
  //   }
  // }, [valueDistrict]);

  // useEffect(() => {
  //   if (valueFormat === "") setErrorDepartment("Сначала выберите формат");
  //   else {
  //     setErrorDepartment("");
  //     setDepartments([]);
  //     setErrorFormat("");
  //     setShops([]);
  //     setValueShop("");
  //     setDepartments([]);
  //     setValueDepartment("");
  //     api
  //       .getDepartments(valueDistrict, valueFormat)
  //       .then((res) => setDepartments(res.data));
  //   }
  // }, [valueFormat, valueDistrict]);

  // useEffect(() => {
  //   if (valueDepartment === "") setErrorShop("Сначала выберите подразделение");
  //   else {
  //     setErrorShop("");
  //     setShops([]);
  //     api.getShops(valueDepartment, valueFormat).then((res) => {
  //       if (res.data.length > 0) setShops(res.data);
  //       else setErrorShop("Не найдено");
  //     });
  //   }
  // }, [valueDepartment, valueFormat]);

  const checkboxRef: RefObject<HTMLSpanElement> = useRef(null);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (
      !isValidEmail(valueEmail) ||
      valuePhone.match(/\d/g)?.length !== 11 ||
      valueName === "" ||
      valueSurname === "" ||
      valuePhone === "" ||
      valueEmail === "" ||
      valueStory === "" ||
      // valuePatronymic === "" ||
      valueJob === "" ||
      valueDistrict === "" ||
      (departments.length > 0 && valueDepartment === "") ||
      valueFormat === "" ||
      (shops.length > 0 && (valueShop === ""))
    ) {
      if (valueName === "") setEmptyErrorName(true);
      if (valueSurname === "") setEmptyErrorSurname(true);
      if (valuePhone === "") setEmptyErrorPhone(true);
      if (valueEmail === "") setEmptyErrorEmail(true);
      if (valueStory === "") setEmptyErrorStory(true);
      // if (valuePatronymic === "") setEmptyErrorPatronymic(true);
      if (valueJob === "") setEmptyErrorJob(true);
      if (valueDistrict === "") setEmptyErrorDistrict(true);
      if (valueFormat === "") setEmptyErrorFormat(true);
      if (departments.length > 0 && valueDepartment === "") setEmptyErrorDepartment(true);
      if (shops.length > 0 && valueShop === "") setEmptyErrorShop(true);
      if (!isValidEmail(valueEmail)) setError("Невалидный email");
      if (valuePhone.match(/\d/g)?.length !== 11)
        setError("Невалидный номер телефона");
      if (!checked) {
        checkboxRef.current?.classList.add(styles.checkbox_error);
        setTimeout(() => {
          checkboxRef.current?.classList.remove(styles.checkbox_error);
        }, 1200);
        // debugger
      }
    } else {
      const result = {
        firstName: valueName,
        lastName: valueSurname,
        fathersName: valuePatronymic,
        job: valueJob,
        district: valueDistrict,
        format: valueFormat,
        department: departments.length > 0 ? valueDepartment : "",
        shop: shops.length > 0 ? valueShop : "",
        phone: valuePhone,
        mail: valueEmail,
        why: valueStory,
      };

      const final = props.final;
      final.step4 = result;
      props.setFinal(final);
      props.nextPage();
    }
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  console.log(`valuePhone`, valuePhone);

  return (
    <div className={styles.stepFour}>
      <img className={styles.circle} src={circle} alt="circle" />
      <div className={styles.desc}>
        <div className={`${styles.desc_wrapper}  ${styles.wrapper}`}>
          <div className={styles.desc_humanImg}>
            <img src={backgroundImg} alt="human" />
          </div>
          <div className={styles.desc_img}>
            <img src={step4} alt="step4" />
          </div>
          <div className={styles.desc_text}>
            <div className={styles.text_step}>шаг 4</div>

            <div className={styles.text_title}>
              Расскажите, кто он –<br />
              «Человек-Магнит»?
            </div>
            <div className={styles.text_desc}>
              Они – лучшие в своём деле, надёжные коллеги, прекрасные
              наставники, мудрые руководители. Кто эти люди, на которых всё
              держится?
              <br />
              <div  className={styles.text_desc2}>
                Поделитесь именем того, кого лично вы считаете <br />
                Человеком «Магнита» с большой буквы!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.form}>
        <div className={`${styles.form_wrapper} ${styles.wrapper}`}>
          <div className={styles.form_title}>
            <span>Человек</span>
            <span> «Магнита»</span>
          </div>
          <div className={styles.form_inputs}>
            <InputField
              placeholder="Фамилия"
              placeholderBig="Фамилия"
              emptyError={emptyErrorSurname}
              setEmptyError={setEmptyErrorSurname}
              value={valueSurname}
              setValue={setValueSurname}
            />
            <InputField
              placeholder="Имя"
              placeholderBig="Имя"
              emptyError={emptyErrorName}
              setEmptyError={setEmptyErrorName}
              value={valueName}
              setValue={setValueName}
            />
            <InputField
              placeholder="Отчество"
              placeholderBig="Отчество"
              emptyError={emptyErrorPatronymic}
              setEmptyError={setEmptyErrorPatronymic}
              value={valuePatronymic}
              setValue={setValuePatronymic}
            />
            <InputField
              placeholder="Должность"
              placeholderBig="Должность"
              emptyError={emptyErrorJob}
              setEmptyError={setEmptyErrorJob}
              value={valueJob}
              setValue={setValueJob}
            />
            <Dropdown
              placeholder="Округ"
              value={valueDistrict}
              placeholderBig="Выберите округ"
              options={districts.map((el) => el.name)}
              emptyError={emptyErrorDistrict}
              setEmptyError={setEmptyErrorDistrict}
              setValue={handleDistrict}
            />
            <Dropdown
              placeholder="Формат"
              placeholderBig="Выберите формат"
              value={valueFormat}
              options={formats.map((el) => el.formats)}
              emptyError={emptyErrorFormat}
              setEmptyError={setEmptyErrorFormat}
              setValue={handleFormat}
              parentError={errorFormat}
            />
            {departments.length > 0 && <Dropdown
              placeholder="Подразделение"
              placeholderBig="Выберите бизнес-подразделение"
              options={departments}
              value={valueDepartment}
              emptyError={emptyErrorDepartment}
              setEmptyError={setEmptyErrorDepartment}
              setValue={handleDepartments}
              parentError={errorDepartment}
            /> }

            {shops.length > 0  && (
              <Dropdown
                placeholder="Магазин"
                placeholderBig="Выберите магазин"
                options={shops}
                value={valueShop}
                emptyError={emptyErrorShop}
                setEmptyError={setEmptyErrorShop}
                setValue={setValueShop}
                parentError={errorShop}
              />
            )}

            <InputField
              placeholder="Телефон"
              placeholderBig="Телефон"
              emptyError={emptyErrorPhone}
              setEmptyError={setEmptyErrorPhone}
              value={valuePhone}
              setValue={(...props) => {
                setValuePhone(...props);
                setError(null);
              }}
              type="phone"
            />
            <InputField
              placeholder="Почта"
              placeholderBig="Почта"
              emptyError={emptyErrorEmail}
              setEmptyError={setEmptyErrorEmail}
              value={valueEmail}
              setValue={(...props) => {
                setValueEmail(...props);
                setError(null);
              }}
            />
            <InputField
              placeholder="Расскажите, почему выбрали именно этого человека?"
              placeholderBig="Опишите подробно, не стесняйтесь"
              emptyError={emptyErrorStory}
              setEmptyError={setEmptyErrorStory}
              value={valueStory}
              setValue={setValueStory}
              textarea={true}
            />
            {error && (
              <div style={{ color: "red", marginBottom: "16px" }}>{error}</div>
            )}
          </div>
          <div className={styles.form_btn}>
            <div className={styles.btn_container}>
              <Button handleSubmit={handleSubmit} text={"ОТПРАВИТЬ"} />
            </div>
            <span className={styles.input_agreement}>
              Пользователь гарантирует законность предоставления персональных
              данных лица,чьи данные он предоставляет, осведомлённость
              и согласие такого лица на предоставление его персональных данных
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFour;
