import React, { Dispatch, SetStateAction } from "react";
import InputMask from "react-input-mask";
import styles from "./InputField.module.scss";

interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  placeholder: string;
  placeholderBig: string;
  emptyError: boolean;
  setEmptyError: Dispatch<SetStateAction<boolean>>;
  textarea?: boolean;
  spanPlaceholder?: boolean;
  limit?: number;
  type?: "phone";
}

const InputField: React.FC<Props> = (props) => {
  const onInputFocus = () => {
    if (props.emptyError) {
      props.setEmptyError(false);
      props.setValue("");
    }
  };

  const Input =
    props.type === "phone" ? (
      <InputMask
        className={`${styles.input} ${props.emptyError && styles.input_error}`}
        mask="+7 (999) 999 99 99"
        onChange={(event) => props.setValue(event.target.value)}
        value={props.value}
      />
    ) : (
      <input
        type="text"
        placeholder={props.placeholderBig}
        onFocus={onInputFocus}
        maxLength={props.limit}
        onChange={(event) => props.setValue(event.target.value)}
        value={`${props.emptyError ? "Заполните это поле" : props.value}`}
        className={`${styles.input} ${props.emptyError && styles.input_error}`}
      />
    );

  return (
    <div
      className={`${styles.wrapper} ${
        props.emptyError ? styles.wrapper_error : ""
      } ${props.textarea && styles.mobileStyle}`}
    >
      <span
        className={`${styles.placeholderWrapper} ${
          props.spanPlaceholder && styles.displayNone
        }`}
      >
        {props.placeholder}
      </span>
      {props.textarea ? (
        <textarea
          placeholder={props.placeholderBig}
          className={`${styles.textarea} ${
            props.emptyError && styles.input_error
          }`}
          onFocus={onInputFocus}
          onChange={(event) => props.setValue(event.target.value)}
          maxLength={props.limit}
          value={`${props.emptyError ? "Заполните это поле" : props.value}`}
        />
      ) : (
        Input
      )}
    </div>
  );
};

export default InputField;
