import React from 'react';
import styles from "./Prizes.module.scss"
import ButtonSmall from "../../../../common/ButtonSmall/ButtonSmall";
import Item from "./Item";
import win from "../../../../assets/images/Landing/Prizes/win.svg"
import tShirt from "../../../../assets/images/Landing/Prizes/tshirt.svg"
import t_Shirt from "../../../../assets/images/Landing/Prizes/t-shirt.png"
import t_Shirt_mob from "../../../../assets/images/Landing/Prizes/mobileTshirt.png"
import tick from "../../../../assets/images/Landing/Prizes/tick.svg"
import Button from "../../../../common/Button/Button";
import {useHistory} from "react-router";



interface Props {

}

const Prizes: React.FC<Props> = (props) => {
    const history = useHistory()

    const handleSubmit = () => {
        history.push("/register")
        window.scrollTo(0, 0)
    }


    return (
        <div className={styles.wrapper}>
            {/* <ButtonSmall text="Стать автором" style={{bottom: "auto", top: "25px"}} handleSubmit={handleSubmit}/> */}
            <h1>Самые яркие варианты получат дополнительные призы!</h1>
            <div className={styles.emojiWrapper}>
                <Item emoji="🤩"
                      textBig="Самый креативный"
                      textSmall="Оцениваются находчивость и оригинальность"/>
                <Item emoji="🧐"
                      textBig="Самый мудрый"
                      textSmall="Бросьте вызов древним философам!"/>
                <Item emoji="🤐"
                      textBig="Самый ёмкий"
                      textSmall="Как говорил классик, краткость — сестра таланта"/>
                <Item emoji="😂"
                      textBig="Самый смешной"
                      textSmall="Тут всё и так понятно"/>

            </div>
            <div className={styles.winWrapper} >
                {/* <h1>Выиграй футболку мечты!</h1> */}
                <img src={t_Shirt} className={styles.tShirtImg} alt="T-Shirt"/>
                <img src={t_Shirt_mob} className={styles.tShirtImg_mob} alt="T-Shirt"/>
                <button className={styles.winBtn} onClick={handleSubmit}> Хочу такую!</button>
            </div>
            {/* <img src={tShirt} className={styles.tShirtImg} alt="T-Shirt"/> */}
      
        </div>
    );
};

export default Prizes;